let languageList = [
    {id: "en", name: 'English', value: 1, flag: '/images/lang/en.svg'},
    {id: "du", name: 'Dutch', value: 2, flag: '/images/lang/du.svg'}
];
let environment = 'dev'; /*local, dev, production*/
let apiUrl = 'http://localhost:5001/api/v1/';

if (environment === 'dev') {
    apiUrl = 'https://claus.neutroveg.com/api/v1/';
}
if (environment === 'production') {
    apiUrl = 'https://claus.neutroveg.com/api/v1/';
}
export const LANGUAGE_OPTION = languageList;
export const DEFAULT_LANGUAGE = 'en';
export const API_URL = apiUrl;
export const DECRYPT_KEY = 'r5u8x/A?D(G+KbPeShVmYq3s6v9y$B&E';
export const DECRYPT_iv = '5fgf5HJ5g2752156';
