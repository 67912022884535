import React, {useState} from "react";
import {useTranslation} from "react-i18next";

function Home() {
    const {t} = useTranslation();
    const [isHistoryMore, setIsHistoryMore] = useState(false);

    return (
        <main className="site-main">
            <div className="site-slider">
                <div className="canoes-slider">
                    <div>
                        <div className="canoes-slide">
                            <div className="canoes-slide-thumb">
                                <img src="/images/banner.jpg" alt="Banner"/>
                            </div>
                            <div className="canoes-slide-wrap">
                                <div className="container">
                                    <div className="canoes-slide-content">
                                        <h1>Enjoy your life </h1>
                                        <h5>Explore Beautiful Places</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="canoes-slide">
                            <div className="canoes-slide-thumb">
                                <img src="/images/banner.jpg" alt="Banner"/>
                            </div>
                            <div className="canoes-slide-wrap">
                                <div className="container">
                                    <div className="canoes-slide-content">
                                        <h1>Enjoy your life </h1>
                                        <h5>Explore Beautiful Places</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="canoes-slide">
                            <div className="canoes-slide-thumb">
                                <img src="/images/banner.jpg" alt="Banner"/>
                            </div>
                            <div className="canoes-slide-wrap">
                                <div className="container">
                                    <div className="canoes-slide-content">
                                        <h1>Enjoy your life </h1>
                                        <h5>Explore Beautiful Places</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="canoes-slide">
                            <div className="canoes-slide-thumb">
                                <img src="/images/banner.jpg" alt="Banner"/>
                            </div>
                            <div className="canoes-slide-wrap">
                                <div className="container">
                                    <div className="canoes-slide-content">
                                        <h1>Enjoy your life </h1>
                                        <h5>Explore Beautiful Places</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="canoes-slide">
                            <div className="canoes-slide-thumb">
                                <img src="/images/banner.jpg" alt="Banner"/>
                            </div>
                            <div className="canoes-slide-wrap">
                                <div className="container">
                                    <div className="canoes-slide-content">
                                        <h1>Enjoy your life </h1>
                                        <h5>Explore Beautiful Places</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="site-section self-service-section">
                <div className="container">
                    <div className="row align-items-lg-center">
                        <div className="col-12 col-lg-6 col-xl-5 order-lg-2">
                            <div className="self-service-thumb">
                                <img src="/images/roya-ann-miller.jpg" alt="roya-ann-miller"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7 order-lg-1">
                            <div className="self-service-content">
                                <h2>{t("home_self_service_content_h2")}</h2>
                                <h4>{t("home_self_service_content_h4")}</h4>
                                <p>{t("home_self_service_content_p")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="site-section osgain-work-section">
                <div className="container">
                    <div className="site-section-header">
                        <h2>{t("home_osgian_work")}</h2>
                    </div>
                    <div className="osgain-work-container">
                        <div className="row justify-content-sm-center">
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="osgain-work-action">
                                    <div className="osgain-work-thumb">
                                        <img src="/images/icon-book.png" alt={t("book")}/>
                                    </div>
                                    <h4>{t("book")}</h4>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="osgain-work-action">
                                    <div className="osgain-work-thumb">
                                        <img src="/images/icon-unlock.png" alt={t("unlock")}/>
                                    </div>
                                    <h4>{t("unlock")}</h4>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="osgain-work-action">
                                    <div className="osgain-work-thumb">
                                        <img src="/images/icon-paddle.png" alt={t("paddle")}/>
                                    </div>
                                    <h4>{t("paddle")}</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="site-section our-history-section">
                <div className="our-history-container">
                    <div className="our-history-thumb">
                        <img src="/images/boats.jpg" alt="boats"/>
                    </div>
                    <div className="our-history-col">
                        <div className="our-history-content">
                            <div className="our-history-content-wd">
                                <h3>{t("home_our_story")}</h3>
                                <p>{t('home_our_story_content')}</p>
                                {isHistoryMore && (
                                    <>
                                        <p>{t('home_our_story_content')}</p>
                                        <div className="btn btn-link"
                                             onClick={(e) => setIsHistoryMore(false)}>{t("read_less")}</div>
                                    </>
                                )}
                                {!isHistoryMore && (
                                    <div className="btn btn-link"
                                         onClick={(e) => setIsHistoryMore(true)}>{t("read_more")}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="site-section features-section pb-0">
                <div className="container">
                    <div className="row icon-boat">
                        <div className="col-12 col-md-6">
                            <div className="features-card">
                                <div className="features-content">
                                    <h3 dangerouslySetInnerHTML={{__html: t("features-content")}}></h3>
                                    <p>{t("features-description")}</p>
                                </div>
                                <div className="features-border border_1"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="features-card">
                                <div className="features-content">
                                    <h3 dangerouslySetInnerHTML={{__html: t("convenient_sup")}}></h3>
                                    <p>{t("convenient_sup_desc")}</p>
                                </div>
                                <div className="features-border border_2"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="features-card">
                                <div className="features-content">
                                    <h3 dangerouslySetInnerHTML={{__html: t("kayak_for")}}></h3>
                                    <p>{t("kayak_for_desc")}</p>
                                </div>
                                <div className="features-border border_3"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="features-card">
                                <div className="features-content">
                                    <h3>{t("perfect_activity")}</h3>
                                    <p>{t("perfect_activity_desc")}</p>
                                </div>
                                <div className="features-border border_4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="site-section py-0">
                <div className="container">
                    <div className="site-section-header">
                        <h2>{t("what_people_about_us")}</h2>
                    </div>
                </div>

                <div className="project-facts-wrap">
                    <div className="container">
                        <div className="project-facts-row">
                            <div className="project-facts-col">
                                <div className="project-facts-content">
                                    <p id="number1" className="number plus-number">105</p>
                                    <p>{t("established_osagian")}</p>
                                </div>
                            </div>
                            <div className="project-facts-col">
                                <div className="project-facts-content">
                                    <p id="number2" className="number plus-number">15000</p>
                                    <p>{t("satisfied_customers")}</p>
                                </div>
                            </div>
                            <div className="project-facts-col">
                                <div className="project-facts-content">
                                    <p id="number3" className="number">80</p>
                                    <p>{t("operators")}</p>
                                </div>
                            </div>
                            <div className="project-facts-col">
                                <div className="project-facts-content">
                                    <p id="number4" className="number">2</p>
                                    <p>{t("operators")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="site-section faq-section">
                <div className="container">
                    <div className="row align-items-lg-center">
                        <div className="col-12 col-lg-4">
                            <div className="site-section-header">
                                <h2>{t("frequently_questions")}</h2>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            {t("frequently_questions_one")}
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                         aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <h4 className="sub-title">{t("frequently_accordion")}</h4>
                                            <p>{t("frequently_accordion_body")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                            {t("frequently_questions_one")}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>{t("frequently_accordion_two")}</strong> {t("frequently_accordion_body")}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                            {t("frequently_questions")}
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                         aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <h4 className="sub-title">{t("frequently_accordion")}</h4>
                                            <p>{t("frequently_accordion_body")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="site-section testimonial-section">
                <div className="container">
                    <div className="site-section-header">
                        <h2>{t("what_people_about_us")}</h2>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="testimonial-card">
                                <div className="testimonial-card-header">
                                    <p>12 Dec 2023</p>
                                    <p>7:00 PM</p>
                                </div>
                                <div className="testimonial-card-body">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard.</p>
                                </div>
                                <div className="testimonial-card-user">
                                    <div className="testimonial-card-user-thumb">
                                        <img src="/images/david.jpg" alt="david"/>
                                    </div>
                                    <h5>Mr David Adam</h5>
                                    <p>CEO, Karton</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="testimonial-card">
                                <div className="testimonial-card-header">
                                    <p>12 Dec 2023</p>
                                    <p>7:00 PM</p>
                                </div>
                                <div className="testimonial-card-body">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard.</p>
                                </div>
                                <div className="testimonial-card-user">
                                    <div className="testimonial-card-user-thumb">
                                        <img src="/images/eli.jpg" alt="eli"/>
                                    </div>
                                    <h5>Mr David Adam</h5>
                                    <p>CEO, Karton</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="testimonial-card">
                                <div className="testimonial-card-header">
                                    <p>12 Dec 2023</p>
                                    <p>7:00 PM</p>
                                </div>
                                <div className="testimonial-card-body">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard.</p>
                                </div>
                                <div className="testimonial-card-user">
                                    <div className="testimonial-card-user-thumb">
                                        <img src="/images/man.jpg" alt="man"/>
                                    </div>
                                    <h5>Mr David Adam</h5>
                                    <p>CEO, Karton</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;