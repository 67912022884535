import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DEFAULT_LANGUAGE, LANGUAGE_OPTION} from "../../configs/AppConfig";

function Header() {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const location = useLocation();
    const [langImg, setLangImg] = useState(DEFAULT_LANGUAGE);
    useEffect(function () {
        if (lang) {
            let langList = LANGUAGE_OPTION;
            let index = langList.findIndex(x => x.id === lang);
            if (index > -1) {
                i18n.changeLanguage(lang);
                setLangImg(lang);
            } else {
                i18n.changeLanguage(DEFAULT_LANGUAGE);
                setLangImg(DEFAULT_LANGUAGE);
                navigate('/404');
            }
        }
    }, [lang, i18n, navigate]);
    const handleLanguageChange = (e, data) => {
        e.preventDefault();
        const newLang = data.id;
        if (lang && lang === newLang) {
            return false;
        }
        if (!lang && newLang === DEFAULT_LANGUAGE) {
            return false;
        }
        setLangImg(newLang);
        i18n.changeLanguage(newLang);
        if (newLang === DEFAULT_LANGUAGE) {
            const newPath = location.pathname.substring(3);
            if (newPath) {
                navigate(newPath);
            } else {
                navigate('/');
            }
        } else {
            if (location.pathname === '/') {
                navigate('/' + newLang);
            } else {
                navigate('/' + newLang + location.pathname);
            }
        }
    };

    return (
        <header className="site-header">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-container">
                        <div className="site-logo">
                            <NavLink to={!lang ? "/" : `/` + lang}>
                                <img src="/images/logo.png" alt="Logo"/>
                            </NavLink>
                        </div>

                        <div className="dropdown lang-dropdown">
                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                <img src={`/images/lang/${langImg}.svg`} alt="flag-united-kingdom"/>
                                <i className="bi bi-chevron-down"></i>
                            </button>
                            <ul className="dropdown-menu">
                                {LANGUAGE_OPTION.map((item, index) =>
                                    <li className='cur-pointer' key={index}
                                        onClick={(e) => handleLanguageChange(e, item)}>
                                        <div className="dropdown-item">
                                            <img src={item.flag}
                                                 alt="flag-united-kingdom"/>{item.name}
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                            className="bi bi-list"></i></button>

                        <div className="offcanvas offcanvas-menu offcanvas-end" tabIndex="-1" id="offcanvasRight"
                             aria-labelledby="offcanvasRightLabel">
                            <div className="offcanvas-header">
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" end
                                                 to={!lang ? "/" : `/` + lang}>{t("menu_home")}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link"
                                                 to={!lang ? "/safety" : `/` + lang + `/safety`}>{t("menu_saftey")}</NavLink>
                                    </li>
                                    <li className="nav-item nav-logo">
                                        <NavLink className="nav-link"
                                                 to={!lang ? "/faq" : `/` + lang + `/faq`}>{t("menu_faq")}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link"
                                                 to={!lang ? "/contact" : `/` + lang + `/contact`}>{t("menu_contact")}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </header>);
}

export default Header;