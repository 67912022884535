import AxoisInstance from "./AxoisInstance";

export function contactUs(languageId, data) {
    let config = {
        headers: {
            "language": languageId,
        }
    };
    return AxoisInstance.post(`contact-us`, data, config);
}

export function SubscribeUser(languageId, data) {
    let config = {
        headers: {
            "language": languageId,
        }
    };
    return AxoisInstance.post(`subscriber`, data, config);
}

export function Settings(data) {
    return AxoisInstance.get(`settings`, data);
}
