import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import validator from 'validator';
import {contactUs} from "../services/CommonService";
import {toast} from "react-toastify";
import {decryptData, encryptData} from "../utils/crypto";
import Utils from "../utils";

function Contact() {
    const {i18n, t} = useTranslation();
    const [loading, setLoading] = useState(false);
    let errorsObj = {
        name: "",
        location: "",
        email: "",
        phone_number: ""
    };
    const [errors, setErrors] = useState(errorsObj);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [phone, setPhone] = useState('');

    function onContactDetails(e) {
        e.preventDefault();
        let error = false;
        const errorObj = {...errorsObj};

        if (!name) {
            errorObj.name = t("name_must_be_required");
            error = true;
        }
        if (!email) {
            errorObj.email = t("email_must_be_required");
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = t('please_enter_valid_email');
            error = true;
        }

        if (!location) {
            errorObj.location = t("location_must_be_required");
            error = true;
        }
        if (!phone) {
            errorObj.phone_number = t("phone_must_be_required");
            error = true;
        } else if (phone.length <= 5) {
            errorObj.phone_number = t('enter_valid_phone_number');
            error = true;
        }

        setErrors(errorObj);
        if (error) return;

        setLoading(true);

        let languageId = Utils.getLanguageId(i18n.language);

        let obj = {
            name: name,
            email: email,
            location: location,
            phone_number: phone,
        };

        let encryptObj = encryptData(obj);

        contactUs(languageId, encryptObj)
            .then(response => {
                const decryptedContact = decryptData(response.data);
                setName('');
                setEmail('');
                setLocation('');
                setPhone('');
                setTimeout(function () {
                    setLoading(false);
                }, 500);
                toast.success(decryptedContact.message);
            }).catch(err => {
            toast.error(err);
            setLoading(false);
        });
    }

    return (<>
        {loading && <div className="page-loading">
            <img src="/images/loader.gif" alt="loader"/>
        </div>}
        <main className="site-main">
            <div className="site-banner contact-banner">
                <div className="site-banner-thumb">
                    <img src="/images/contact-banner.jpg" alt="Contact Banner"/>
                </div>
                <div className="site-banner-wrap">
                    <div className="container">
                        <div className="site-banner-content">
                            <h1>Contact </h1>
                            <h6>Fill in your details and we'll be in touch.</h6>
                        </div>
                        <div className="site-banner-content-action">
                            <img src="/images/icon-phone.svg" alt="Phone"/>
                        </div>
                    </div>
                </div>
            </div>
            <section className="site-section contact-section">
                <div className="container">
                    <form onSubmit={onContactDetails}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 mb-xl-4">
                                    <label htmlFor="name" className="form-label">{t('name')}</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="name-addon"><i
                                            className="bi bi-person"></i></span>
                                        <input className="form-control" id="name" value={name}
                                               onChange={(e) => setName(e.target.value)}
                                               placeholder={t("enter_name")} aria-describedby="name-addon"/>
                                    </div>
                                    {errors.name && (
                                        <div className="text-danger form-text">{errors.name}</div>)}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 mb-xl-4">
                                    <label htmlFor="email" className="form-label">{t('email_address')}</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="email-addon"><i
                                            className="bi bi-envelope"></i></span>
                                        <input className="form-control" id="email" value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               placeholder={t("enter_email_address")} aria-describedby="email-addon"/>
                                    </div>
                                    {errors.email && (
                                        <div className="text-danger form-text">{errors.email}</div>)}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 mb-xl-4">
                                    <label htmlFor="location" className="form-label">{t('location')}</label>
                                    <div className="input-group textarea-group">
                                        <span className="input-group-text" id="location-addon"><i
                                            className="bi bi-geo-alt"></i></span>
                                        <textarea className="form-control" aria-label="With textarea" id="location"
                                                  value={location}
                                                  onChange={(e) => setLocation(e.target.value)}
                                                  placeholder={t("enter_address")}
                                                  aria-describedby="location-addon"></textarea>
                                    </div>
                                    {errors.location && (
                                        <div className="text-danger form-text">{errors.location}</div>)}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 mb-xl-4">
                                    <label htmlFor="number" className="form-label">{t('phone_number')}</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="number-addon"><i
                                            className="bi bi-telephone"></i></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phone_number"
                                            value={phone}
                                            maxLength={12}
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                if (input.length <= 12 && /^\d*$/.test(input)) {
                                                    setPhone(input);
                                                }
                                            }}
                                            placeholder={t("enter_phone_number")}
                                            aria-describedby="number-addon"
                                        />

                                    </div>
                                    {errors.phone_number && (
                                        <div className="text-danger form-text">{errors.phone_number}</div>)}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <button type="submit" className="btn btn-primary w-100">{t('contact_me')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    </>);
}

export default Contact;