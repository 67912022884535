import {LANGUAGE_OPTION} from "../configs/AppConfig";

class Utils {
    static getLanguageId(lang) {
        let id = 1;
        if (lang) {
            let index = LANGUAGE_OPTION.findIndex(x => x.id === lang);
            if (index > -1) {
                id = LANGUAGE_OPTION[index]['value'];
            }
        }
        return id;
    }
}

export default Utils;